import { createHttpLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getEnvVar } from '../../../helpers/commons';

const notificationsLink = createHttpLink({
  uri: getEnvVar('REACT_APP_NOTIFICATION_BFF_ENDPOINT'),
  credentials:
    getEnvVar('REACT_APP_IS_LOCAL') === 'true' ? 'include' : 'same-origin'
});

const compensationsLink = createUploadLink({
  uri: getEnvVar('REACT_APP_COMPENSATION_BFF_ENDPOINT'),
  credentials:
    getEnvVar('REACT_APP_IS_LOCAL') === 'true' ? 'include' : 'same-origin'
});

const myEvolutionLink = createUploadLink({
  uri: getEnvVar('REACT_APP_MY_EVOLUTION_BFF_ENDPOINT'),
  credentials:
    getEnvVar('REACT_APP_IS_LOCAL') === 'true' ? 'include' : 'same-origin'
});

const requestsLink = createHttpLink({
  uri: getEnvVar('REACT_APP_REQUESTS_BFF_ENDPOINT'),
  credentials:
    getEnvVar('REACT_APP_IS_LOCAL') === 'true' ? 'include' : 'same-origin'
});

const requestsContext = split(
  (operation) => operation.getContext().clientName === 'requests',
  requestsLink,
  notificationsLink
);

const myEvolutionContext = split(
  (operation) => operation.getContext().clientName === 'myEvolution',
  myEvolutionLink,
  requestsContext
);

const compensationsContext = split(
  (operation) => operation.getContext().clientName === 'compensations',
  compensationsLink,
  myEvolutionContext
);

const federations = compensationsContext;

export default federations;
