import { find } from 'lodash';

export const getSettingValue = (setting) => {
  try {
    if (!setting) return null;
    if (setting.__typename === 'BooleanValue') {
      return !!setting.booleanValue;
    }
    if (setting.__typename === 'StringValue') {
      return setting.stringValue;
    }
    if (setting.__typename === 'IntValue') {
      return setting?.intValue;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getValueKeySetting = (listSettings, key) => {
  const setting = find(listSettings, (item) => item.key === key);

  return getSettingValue(setting?.value);
};
