import React from 'react';
import { Row } from 'antd';
import { useQuery } from '@apollo/client';
import TableComponent from '../../../components/mievolucion/MyPerformance/Performance/TableComponent';
import { useWindowWidthSize } from '../../../helpers/browser';
import { smallDevice } from '../../../helpers/deviceVariables';
import useTableComponent from '../../../hooks/myEvolution/myPerformance/useTableComponent';
import PulsesSteps from '../../../components/mievolucion/MyPerformance/Performance/PulsesComponent';
import ErrorMessage from '../../../components/shared/ErrorMessage';
import { GET_SETTING } from '../../../graphql/settings/queries';
import { getSettingValue } from '../../../helpers/settings';
import Loading from '../../loading';

const Pulsos = () => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  const tableProps = useTableComponent();
  const {
    data: setting,
    loading: loadingSetting,
    error: enabledPulsoError
  } = useQuery(GET_SETTING, {
    fetchPolicy: 'network-only',
    context: { clientName: 'myEvolution' },
    variables: {
      key: 'ENABLED_PULSO'
    }
  });

  if (loadingSetting) {
    return (
      <Loading />
    );
  }

  const isPulseEnabled = getSettingValue(setting?.getSetting?.value);

  return (
    <div className='ml-my-evloution-my-performance'>
      {
        tableProps.myPerformance.error || enabledPulsoError
          ? <ErrorMessage message={'Ocurrió un error al cargar tu desempeño'} />
          : <div>
            <Row>
              <PulsesSteps
                isMobile={isMobile} loading={tableProps.myPerformance.loading} />
            </Row>
            <Row>
              <TableComponent
                isPulseEnabled={isPulseEnabled}
                isMobile={isMobile} tableProps={tableProps} />
            </Row>
          </div>
      }
    </div>
  );
};

export default Pulsos;
