import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Separator from '../../../../shared/Separator';
import InfoCollapse from '../../../../shared/collapses/InfoCollapse';
import InfoMessage from '../../../../shared/InfoMessage';
import CommentForm from './forms/CommentForm';
import AdvanceCommentsDescription from './AdvanceCommentsDescription';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import '../../../../../static/styles/mievolucion/objectives2022/advance-comments.less';
import { FIRST_PULSE, MONTH_ADVANCE_COMMENTS_BY_Q } from '../../../../../helpers/myEvolution/myPerformance';

const AdvanceComments = ({
  bossParams,
  currentQuarter,
  closePerformance,
  comments: commentsReq,
  showBossInputComment,
  editAdvance,
  formRef,
  hideTitle,
  isMobile,
  mainInfoMessage,
  messages
}) => {
  const commentsImpulso = commentsReq.q3;
  const commentsPulso = commentsReq.q2;

  const defaultPaneKeyImpulso = ['0'];
  const defaultPaneKeyPulso = ['0'];
  const { data: performance = {} } = usePerformance();
  const {
    status, atLeastOnceClosedStatus,
    statusByQuarter,
    user
  } = performance;
  const bossMode = !!bossParams;
  const [comments, setComments] = useState({
    q2: {
      commentColaborate: commentsPulso?.commentColaborate,
      commentBoss: commentsPulso?.commentBoss || ''
    },
    q3: {
      commentColaborate: commentsImpulso?.commentColaborate,
      commentBoss: commentsImpulso?.commentBoss || ''
    }
  });

  const [paneKeysImpulso, setPanelKeyImpulso] = useState(defaultPaneKeyImpulso);
  const [paneKeysPulso, setPanelKeyPulso] = useState(defaultPaneKeyPulso);

  const onCollapseChangeImpulso = (v) => setPanelKeyImpulso(v);
  const onCollapseChangePulso = (v) => setPanelKeyPulso(v);

  const openedImpulso = paneKeysImpulso.indexOf(defaultPaneKeyImpulso[0]) !== -1;
  const openedPulso = paneKeysPulso.indexOf('0') !== -1;

  const pendingByColaborate = status?.current === 'PENDING_BY_COLABORATE';
  const closed = status?.current === 'CLOSED';
  const sent = status?.current === 'SENT';
  const returned = status?.current === 'RETURNED';

  const getQMessages = (messagesKey, q) => (
    messages?.[messagesKey][q === currentQuarter ? 'current' : 'previous']
  );
  if (!user?.livePulse && user?.objectiveType === 'CENTRALIZADO') return null;

  return (
    <div className='advance-comments'>
      {!hideTitle && <>
        <div className='mo-title'>Comentarios de cierre de desempeño</div>
        <Separator/>
      </>}

      {mainInfoMessage ? (
        <InfoMessage {...mainInfoMessage}/>
      ) : (
        <>
          {bossMode && sent && (
            <InfoMessage {...{
              suave: true,
              color: 'warning',
              message: atLeastOnceClosedStatus ? (
                'Comenta cómo ha sido hasta ahora su desempeño en relación a sus objetivos y competencias.'
              ) : (
                'Si estás de acuerdo con su propuesta, comenta cómo ha sido hasta ahora su desempeño en relación a sus objetivos y competencias. Si no, la puedes devolver para que la edite.'
              ),
              classes: 'full-width'
            }}/>
          )}

          {!bossMode && !isMobile && (pendingByColaborate || returned) && (
            <InfoMessage {...{
              suave: true,
              color: 'warning',
              message: 'Comenta los principales acuerdos y avances que se abordaron en esta conversación respecto a tus objetivos, competencias y desarrollo.'
            }}/>
          )}
        </>
      )}

      {
        currentQuarter === 'q3'
       && <InfoCollapse {...{
         data: { title: `Pulso de cierre (${MONTH_ADVANCE_COMMENTS_BY_Q[3]})` },
         bordered: false,
         forceRender: currentQuarter === 'q3',
         defaultPaneKey: paneKeysImpulso,
         onChange: onCollapseChangeImpulso,
         extra: <i className={`icon-me-competency-${openedImpulso ? 'remove' : 'add'}`}/>
       }} className='info-collapse-pulso'>
         {(isMobile && !sent && !closed) ? (
           <InfoMessage {...{
             suave: true,
             color: 'warning',
             message: 'Aún no has comentado acerca de tu desempeño. Puedes hacerlo desde un computador.'
           }}/>
         ) : (
           <>
             {bossMode && (
               <Row className='boss-advance-comments-description'>
                 <Col xl={bossMode ? 24 : 20}>
                   <AdvanceCommentsDescription {...{
                     comments: commentsImpulso,
                     bossParams,
                     quarter: 'q3',
                     messages: {
                       bossHasNoCommented: getQMessages('bossHasNoCommented', 'q3')
                     }
                   }} />
                 </Col>
               </Row>
             )}

             {(!bossMode || closePerformance) && (
               <Row {...closePerformance && { className: 'boss-advance-comments-description' }}>
                 <Col xl={bossMode ? 24 : 20}>
                   {(commentsImpulso.commentBoss === '' && commentsImpulso.commentColaborate === '') ? (
                     <InfoMessage {...{
                       suave: true,
                       color: 'warning',
                       message: editAdvance ? 'Aún no has realizado comentarios acerca de tu desempeño.' : 'Sin comentarios'
                     }}/>
                   ) : <></>}
                   {(editAdvance || closePerformance) ? (
                     <CommentForm {...{
                       comments,
                       setComments,
                       quarter: 'q3',
                       formRef,
                       bossParams,
                       srcData: {
                         commentColaborate: comments.q3.commentColaborate,
                         commentBoss: comments.q3.commentBoss
                       }
                     }}/>
                   ) : !bossMode && (
                     <AdvanceCommentsDescription {...{
                       comments: commentsImpulso,
                       quarter: 'q3',
                       messages: {
                         bossHasNoCommented: getQMessages('bossHasNoCommented', 'q3')
                       }
                     }} />
                   )}
                 </Col>
               </Row>
             )}
           </>
         )}
       </InfoCollapse>
      }
      { ['q2', 'q3'].includes(currentQuarter) && <InfoCollapse {...{
        data: { title: `Pulso 2 (${MONTH_ADVANCE_COMMENTS_BY_Q[2]})` },
        forceRender: true,
        defaultPaneKey: paneKeysPulso,
        onChange: onCollapseChangePulso,
        extra: <i className={`icon-me-competency-${openedPulso ? 'remove' : 'add'}`}/>
      }} className='info-collapse-pulso'>
        {bossMode && (
          <Row className='boss-advance-comments-description'>
            <Col xl={bossMode ? 24 : 20}>
              { currentQuarter === 'q2' && showBossInputComment
              && <CommentForm {...{
                comments,
                setComments,
                quarter: 'q2',
                formRef,
                bossParams,
                srcData: {
                  commentColaborate: comments.q2.commentColaborate,
                  commentBoss: comments.q2.commentBoss
                }
              }}/>
              }
              <AdvanceCommentsDescription {...{
                comments: commentsPulso,
                bossParams,
                quarter: 'q2',
                messages: {
                  bossHasNoCommented: getQMessages('bossHasNoCommented', 'q2')
                }
              }} />
            </Col>
          </Row>
        )}

        {(!bossMode) && (
          <Row {...{ className: 'boss-advance-comments-description' }}>
            <Col xl={bossMode ? 24 : 20}>
              {(
                statusByQuarter.q2 === 'CLOSED' || currentQuarter === 'q3'
                && (commentsPulso.commentBoss === '' && commentsPulso.commentColaborate === '')) && (
                <InfoMessage {...{
                  suave: true,
                  color: 'info',
                  message: 'No se hicieron comentarios en este pulso.'
                }}/>
              )}
              {

                <div>
                  { currentQuarter === 'q2' && (editAdvance || closePerformance) ? (
                    <CommentForm {...{
                      comments,
                      setComments,
                      quarter: 'q2',
                      formRef,
                      bossParams,
                      srcData: {
                        commentColaborate: comments.q2.commentColaborate,
                        commentBoss: comments.q2.commentBoss
                      }
                    }}/>
                  ) : !bossMode && (

                    <AdvanceCommentsDescription {...{
                      comments: commentsPulso,
                      quarter: 'q2',
                      messages: {
                        bossHasNoCommented: getQMessages('bossHasNoCommented', 'q2')
                      }
                    }} />

                  )}
                </div>
              }
            </Col>
          </Row>
        )}
      </InfoCollapse>
      }
    </div>
  );
};

AdvanceComments.propTypes = {
  currentQuarter: PropTypes.string,
  formRef: PropTypes.object,
  editAdvance: PropTypes.bool,
  closePerformance: PropTypes.bool,
  hideTitle: PropTypes.bool,
  isMobile: PropTypes.bool,
  comments: PropTypes.object,
  bossParams: PropTypes.object,
  mainInfoMessage: PropTypes.object,
  messages: PropTypes.object,
  showBossInputComment: PropTypes.bool
};

AdvanceComments.defaultProps = {
  currentQuarter: FIRST_PULSE,
  formRef: null,
  editAdvance: false,
  closePerformance: false,
  hideTitle: false,
  isMobile: false,
  comments: null,
  bossParams: null,
  mainInfoMessage: null,
  messages: null,
  showBossInputComment: false
};

export default AdvanceComments;
