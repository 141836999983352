import React from 'react';
import PropTypes from 'prop-types';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import { fullCapitalizeFormat } from '../../../../../helpers/strings';
import Separator from '../../../../shared/Separator';
import '../../../../../static/styles/mievolucion/objectives2022/advance-comments-description.less';

const AdvanceCommentsDescription = ({
  comments, bossParams, quarter
}) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent, boss, status } = performance;
  const bossName = fullCapitalizeFormat(comments?.commentWrittenBoss?.name || boss.name);
  const bossHasCommented = !!comments?.commentBoss?.trim();
  const colaboradorHasCommented = !!comments?.commentColaborate?.trim();

  if (!bossHasCommented && !colaboradorHasCommented) {
    return null;
  }

  const emptyBossMessage = () => {
    if (quarter !== quarterCurrent || status.current === 'CLOSED') {
      return bossParams ? 'No hiciste comentarios' : 'Tu jefe no hizo ningún comentario';
    }
    return bossParams ? 'No has hecho ningún comentario' : 'Tu jefe no ha hecho ningún comentario';
  };
  const emptyColaborateMessage = () => {
    if (quarter !== quarterCurrent || status.current === 'CLOSED') {
      return bossParams ? `${bossParams.selectedUser.name} no realizó ningún comentario` : 'No hiciste comentarios';
    }
    return bossParams ? `${bossParams.selectedUser.name} no ha hecho comentarios` : 'No has hecho ningún comentario';
  };
  return (
    <div>
      <div className='advance-comments-description'>
        <div className='my-advance-comment'>
          <label>
            {bossParams ? 'Tu comentario' : `Comentario de tu jefe ${bossName}` }
          </label>
          { bossHasCommented
            ? <p>{comments.commentBoss}</p>
            : <p> {emptyBossMessage()}</p>
          }

        </div>
      </div>
      <Separator />
      <div className='advance-comments-description'>
        <div className='my-advance-comment'>
          <label>
            {bossParams ? `Comentario de ${bossParams.selectedUser.name}` : 'Mi comentario'}
          </label>
          { colaboradorHasCommented
            ? <p>{comments.commentColaborate}</p>
            : <p> {emptyColaborateMessage()}</p>
          }
        </div>
      </div>
    </div>
  );
};

AdvanceCommentsDescription.typeProps = {
  comments: PropTypes.object,
  bossParams: PropTypes.object,
  messages: PropTypes.object,
  quarter: PropTypes.string
};

AdvanceCommentsDescription.defaultProps = {
  comments: null,
  bossParams: null,
  messages: null,
  quarter: undefined
};

export default AdvanceCommentsDescription;
