import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import federations from './federations';
import onErrorLink from './apolloOnErrorLink';
import authLink from './authLink';
import encodeVariablesLink from './encodeVariablesLink';

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.key || null
  }),
  link: from([onErrorLink, encodeVariablesLink, authLink.concat(federations)])
});

export default client;
